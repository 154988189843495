import React from 'react'

import Logo from '../../assets/Logo.png'
import  one from '../../assets/why/1png.png'
import  two from '../../assets/why/2.png'
import  tree from '../../assets/why/3.png'
import map from '../../assets/mapprev.jpg'

import WindowSearch from '../../components/Win/WindowSearch.jsx';
import WindowFormSearch from '../../components/Win/WindowFormSearch.jsx';
import Header from '../Win/Header.jsx'
import About from '../Win/About.jsx'

import img1 from '../../imagenes/header/Group-1.png'
import img2 from '../../imagenes/header/Group-2.png'
import img3 from '../../imagenes/header/Group-3.png'
import M from '../../components/Win/M.jsx';

const Index = () => {
    return ( 
        

        <div className="App">
        {/* <nav className="mm-nav">
        <div className="logo">
          <img className="LogoMoviendo"src={Logo} alt="Logo" />
        </div>
          <ul>
            <li><a href="#">Encuentra tu propiedad</a></li>
            <li><a href="#">Acerca de</a></li>
            <li><a href="#">Contacto</a></li> 
          </ul>
        </nav> */}

        <Header/>
        <About/>
        {/* <header>
          <div className="header-content">
            <div className="text">
              <h1 className="Primary-Size">Una nueva forma de encontrar tu casa</h1>
            </div>
            <div className="image d-none">
              <img src={one} alt="Imagen de fondo" />
            </div>
          </div>
        </header> */}
{/*         
        <section>
          <WindowSearch/>
        </section>
   */}
        {/* <section className='section'>
  
          <div className='box-item-0'>
            <h2>Porque tenemos que buscar?</h2>
            <p >La nueva era de la busqueda llega hacia a ti de una forma rapida y facil</p>
          </div>
  
          <div className='box-item'>
            
          <div className="item-stock">
            <img alt="Imagen1" src={one} />
            <h3>Cuentanos qué buscas</h3>
            <p className="max-500">Especifique la ubicación, el presupuesto y las preferencias de su casa ideal en un sencillo formulario.</p>
          </div>
  
             
          <div className="item-stock">
            <img alt="Imagen2" src={two} />
            <h3>Conectandote con un realtor</h3>
            <p className="max-500"> Te ponemos en contacto conun realtor especializado </p>
          </div>
  
             
          <div className="item-stock">
            <img alt="Imagen3" src={tree} />
            <h3>Te encontramos tu lugar perfecto</h3>
            <p className="max-500">Tu realtor inmobiliario le guía hasta la casa de sus sueños, adaptada a sus preferencias</p>
          </div>
  
          </div>
  
  
  
  
        </section>
        <section className='section'>
  
          
        <div className='box-item-0'>
            <h2>Comienza ya</h2>
            <p >En que area quieres vivir</p>
          </div> 
  
          <div className='mapview-box'>
            <img  src={map} alt="mapview" />
          </div> 
  
        </section> */}
        <section className='section-968 dx3'>
        <div className="box-moviendo-mm">
          <div className=''>
          <h2 className=''>Deja de buscar propiedades que no se ajustan a ti.</h2>
          <p className=''>Olvídate de llamar para verificar disponibilidad.</p>

          </div>
          <div className=''>
          <img
            src={img1}
            alt="Dejanos buscar"
          />

          </div>
          </div>
  
           
             <WindowFormSearch/>
             <div className="box-moviendo-mm">
             <div className=''>
             <img
                src={img2}
                alt="Dejanos buscar"
              />

             </div>

              <div className=''>
              <h2 className=''>Nuestro sistema seleccionará el mejor agente para ti.</h2>

              <img
                src={img3}
                alt="Dejanos buscar"
                className='max-150'
              />

              </div>
             
            </div>
        </section>
      
       
        <M/>
      </div>



    );
}
 
export default Index;