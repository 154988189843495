import React, { useState } from 'react'
import useAct from '../../hooks/useAct';
import useSearchJoe from '../../hooks/useSearchJoe';

import img1x from '../../imagenes/mapimages/PanamaOeste.png'
import img2x from '../../imagenes/mapimages/Bocas-del-Toro.png'
import img3x from '../../imagenes/mapimages/Chiriqui.png'
import img4x from '../../imagenes/mapimages/Cocle.png'
import img5x from '../../imagenes/mapimages/Colon.png'
import img6x from '../../imagenes/mapimages/Darien.png'
import img7x from '../../imagenes/mapimages/GunaYala.png'
import img8x from '../../imagenes/mapimages/Herrera.png'
import img9x from '../../imagenes/mapimages/LosSantos.png'
import img10x from '../../imagenes/mapimages/Ngabe-Bugle.png'
import img11x from '../../imagenes/mapimages/Veraguas.png'
import img12x from '../../imagenes/mapimages/PanamaCiudad.png'
import s from '../../imagenes/iconos/search.svg'
import iconmaestro from '../../imagenes/filtericon/x1.svg'
import { COM, PRO } from '../../microdata/dataLocation'
import LoaderD from '../Loader/LoaderD';
import bg from '../../imagenes/header/bg-box.png';
import ant from '../../imagenes/header/btn/1.png';
import des from '../../imagenes/header/btn/2.png';
import sen from '../../imagenes/header/btn/3.png';
import Logo from '../../assets/Logo.png'


//// aqui vamos a colocar el hook del search ////

export const cuenta = [
    {
        id:0,
        T:'Panamá Oeste',
        D:'08',
        icon:img1x,
    },
    {   id:1,
        T:'Bocas del Toro',
        D:'Actualiza la contraseña y protege tu cuenta',
        icon:img2x,

    },
    {   id:2,
        T:'Chiriquí',
        D:'Revisa pagos, cobros, cupones y tarjetas de regalo',
        icon:img3x,

    },
    {   id:3,
        T:'Coclé',
        D:'Administra la información y los documentos fiscales de los contribuyentes',
        icon:img4x,

    },
    {   id:4,
        T:'Colón',
        D:'Elige las preferencias de notificación y tu forma de contacto',
        icon:img5x,

    },
    {   id:5,
        T:'Darien',
        D:'Administra tus datos personales, servicios relacionados y configuración de uso compartido de datos',
        icon:img6x,

    },
    {   id:6,
        T:'Guna Yala',
        D:'Configura tu idioma, moneda y zona horaria',
        icon:img7x,

    },
    {   id:7,
        T:'Herrera',
        D:'Agrega un correo electrónico de empresa para obtener beneficios en los viajes de negocios',
        icon:img8x,

    },
    {   id:8,
        T:'Los Santos',
        D:'Obtén herramientas profesionales si administras varias propiedades en Airbnb',
        icon:img9x,

    },
    {   id:9,
        T:'Ngäbe-Buglé',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img10x,

    },
    {   id:10,
        T:'Veraguas',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img11x,

    },
    {   id:11,
        T:'Panama',
        D:'Tienes $0 de crédito y cupones por invitaciones. Más información.',
        icon:img12x,

    }
    

]


export const opcionmueble = [
    {
        id:0,
        T:'Amoblado',
      
    },
    {   id:1,
        T:'Linea Blanca',
     

    },
    {   id:2,
        T:'Vacio',
    }

]

export const opcionmascota = [
    {
        id:0,
        T:'Sin Mascota',
      
    },
    {   id:1,
        T:'Tengo Mascota',
     

    }

]


const WindowFormSearch = () => {

  


    const {
        location,
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 ,
        winacc,WinAccD,
        winsearch,WinS,
       
    } = useAct() 

    const {
        setSelectPro,
        selectpro,
        setTerm,
        Provincia,
        Distrito,
        Corregimiento,
        p01,
        p02,
        p03,
        p04,
        Resumen,
        term,
        termI,
        setTermI,
        selectdis, 
        setSelectDis,
        termdistrito, setTermDistrito,
        addTermFinal,
        setFinalTerm,
        setTermCorregimiento,
        loeader, 
        oloader,
        cantidadderesultados,
        filtro,
        termcorregimiento,
        monto,
        numCuartos,
        numBa,
        handleNumBaChange,
        handleMontoChange,
        handleNumCuartosChange,
        telefono,
        email,
        handleM,
        handleT,
        sendPropertyData,
        tipo,
        CompraAlquila,
        Alquilar,
        Comprar,
        Resettipo,
        mascota,setMascota,
                muebles, setMuebles

    } = useSearchJoe()

    const formattedMonto = monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

   


    const [profileactive, setProfileAct] = useState(false);
    
    const limpiar = () => {
        setTermCorregimiento("")
    }

    const LimpiarTodos = () => {
        Provincia()
        setFinalTerm("")
        setTermDistrito("")
        setTermCorregimiento("")
        setTerm("")
        oloader()
        WinS()
    }


    const ParcialLimpiar = () => {
        Provincia()
        setFinalTerm("")
        setTermDistrito("")
        setTermCorregimiento("")
        
    }

    return ( 
            <>
                        
                <div  className={` bg-trans-mm `}>
                    

                <section className={`candela-closet`} 
                onClick={WinS}
                >

                </section>

                    {/* <img
                        src={bg}
                        alt="imagen-alt"
                        className="epic-bg-mm"
                    />
               */}

                    <section className='Form-box'>
                   
                    <section className="yeah-scroll">
                         <div className="logo">
          <img className="LogoMoviendo"src={Logo} alt="Logo" />
        </div>







<section className="box-maestro-search bg-ground" 
onClick={ () =>{
// WinS()
// setTerm("")

}
}
> 



<section className="  newnewnew">

<div 
    className={`ss-button ${term !== '' && p02 === true || p03 === true || p04 === true ? 'Active-fill-fill':''} ${p01 === true ? 'Active-btn-ss':''}`}
    >
        <p className={`elmama ${filtro.provincia !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}>
             {/* <span>{filtro.provincia}</span>  */}
             </p>
<button onClick={
    () => {
        Provincia()
        setFinalTerm("")
        setTermDistrito("")
        setTermCorregimiento("")
        
        oloader()
        

    }
    }>
Provincia
</button>

</div>

<div 
    className={`ss-button ${termdistrito !== '' && p03 === true || p04 === true ? 'Active-fill-fill':''}   ${p02 === true ? 'Active-btn-ss':''}`}
    onClick={p03 === true ? Distrito  : null}
    >

            <p className={`elmama ${filtro.distrito !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}> 
            {/* <span>{filtro.distrito}</span>  */}
            </p>

<button >
    Distrito


</button>
    
</div>

<div
className={`ss-button ${ p04 === true ? 'Active-fill-fill':''}  ${p03 === true ? 'Active-btn-ss':''}`}

    onClick={p02 === true && termdistrito !== "" ? Corregimiento  : null}

    >

<p className={`elmama ${filtro.corregimiento !== "" ? 'hopehopehope ' :'hopehopehopeno' }`}>
     {/* <span>{filtro.corregimiento}</span>  */}
     
     </p>
        <button>
            Corregimiento
            
        </button>
    
</div>

<div className="float-btn-joema d-none">
    <img src={s} />

</div>

</section>
<section className="bottom-information">
                                                <span className={`maricon-jones`}> 
                                                <p className={` ${filtro.provincia !== "" ? 'hopehopehope willonfx-text' :'hopehopehopeno' }`}>
                                                        {/* Provincia : */}
                                                        {/* &nbsp; */}
                                                    <span>{filtro.provincia}</span> 
                                                    
                                                </p>
                                                {/*                                         
                                                &nbsp; */}
                                                <p className={`${filtro.distrito !== "" ? 'hopehopehope willonfx-text ' :'hopehopehopeno' }`}>
                                                    {/* Distrito : */}
                                                    {/* &nbsp;  */}
                                                    <span>{filtro.distrito}</span>
                                                </p>
                                                
                                                <p className={`${filtro.corregimiento !== "" ? 'hopehopehope willonfx-text ' :'hopehopehopeno' }`}>
                                                    {/* Corregimiento : */}
                                                    {/* &nbsp; */}
                                                <span>{filtro.corregimiento}</span> 
                                                
                                                </p>
                                                
                                                </span>
                                            </section>


</section>
<section className="combaattt">

                 
<div className="busqueda-hijo-izquierda loader d-none">

        

{
loeader === true ? 
<>
<div className={`Loader-style-bottom ${loeader === true ? '':'opaopacero'}`}>
<LoaderD/>
</div>

</> 
: 
null
}
                    {/* {
                        cantidadderesultados === 0 ? <>
                        Seleccione, <br/> llene todos los campos, encuentre su propiedad soñada...
                        </> : 
                        <><p>Se han encontrado <span className="bombaso-bbb">{cantidadderesultados}</span> resultados de búsqueda</p> </>
                    } */}

</div>

                  

<div className={`box-siguiente-brutal ${p02 === true ? '' :''}
${p03 === true ? '' :''}
`} >    


              
{
p04 === true ? 
<section>


<h2 className="tttt5">Resumen su búsqueda</h2>


<section className="box-maestro-campos-varios">
<section className="newnewnew-head duo-grid ">
<button 
onClick={
    tipo === '' ? Alquilar : null ||
    tipo === 'Alquiler' ? Resettipo : CompraAlquila 
} 
className={` ${tipo === 'Alquiler' ? 'active-alquiler' : ''} `}
>
Alquiler

</button>
<button
onClick={
    tipo === '' ? Comprar : null || 
    tipo === 'Compra' ? Resettipo : CompraAlquila
} 
className={` ${tipo === 'Compra' ? 'active-alquiler' : ''} `}>
    Compra
    
</button>

</section>


<section className="newnewnew-head duo-grid">
{opcionmascota.map( ree => {
                            return(
                                    <button 
                                     key={ree.id} className={`  ${ ree.T === mascota ? ' active-alquiler ':''}`} 
                                    onClick={ () => {
                                     setMascota(ree.T)
                                
                                }}
                                >
                                    {ree.T}
                                    </button>
                               
                            )
                    })}
</section>


<section className="newnewnew-head trio-grid">
{opcionmueble.map( ree => {
                            return(
                                    <button 
                                     key={ree.id} className={`  ${ ree.T === muebles ? ' active-alquiler ':''}`} 
                                    onClick={ () => {
                                     setMuebles(ree.T)
                                
                                }}
                                >
                                    {ree.T}
                                    </button>
                               
                            )
                    })}
</section>




</section>





<div className={``}>

<section className=" d-grid">
<div className='input-range-arena'>
<h3 className=''>
Presupuesto: {formattedMonto}

</h3>
<p className='text-gray text-left'>
    Ajusta tu presupuesto en dolares, sobre la búsqueda
</p>
<input
type="range"
min="0"
max={tipo === 'Alquiler' ? '5000' : '900000' }
value={monto}
onChange={handleMontoChange}
/>
</div>

<section className="duo-grid d-grid">



<div className='input-range-arena'>
<h3 className='text-'>
Cuartos: {numCuartos}
</h3>

<p className='text-gray text-left'>
    número de habitaciones
</p>
<input
type="range"
min="0"
max="9"
value={numCuartos}
onChange={handleNumCuartosChange}

/>
</div>

<div className='input-range-arena'>
<h3 className='text-'>
Baños: {numBa}
</h3>

<p className='text-gray text-left'>
    Cuantos baños necesitas
</p>
<input
type="range"
min="0"
max="9"
value={numBa}
onChange={handleNumBaChange}

/>
</div>
</section>

<div className='input-text-arena'>
<p className='text-gray text-left'>
    Escriba su correo electrónico
</p>
<input
type="email"
placeholder=" e-mail"
value={email}
onChange={handleM}
/>
</div>

<div className='input-text-arena'>
<p className='text-gray text-left'>
    Escriba su número de teléfono
</p>
<input
type="text"
placeholder=" # teléfono o WhatsApp"
value={telefono}
onChange={handleT}
/>
</div>


</section>




</div>

</section> 




: null
}  
{ p01 === true ?
<>

<section className="goat-search">
<h2 className="tttt">Seleccione la provincia</h2>
<div className={`sscroll`}>
<div className="hit-hit">
{PRO.map( ree => {
                            return(
                                
                                <div key={ree.id} className={`provincias  ${ ree.provincia === term ? 'mari-mari':''}`} 
                                >
                                    <div className="elpapadelos" onClick={ () => {
                                    setTerm(ree.provincia)
                                    setSelectPro(ree.distritos)
                                    setTermI(ree.map)
                                
                                    addTermFinal(ree.provincia)
                                    oloader()
                                        Distrito()
                                    //  WinS()
                                }}
                                >
                                    <img src={ree.map} className="icon-normal-size-30" alt="img"/>
                                            <h2 className="">{ree.provincia}</h2>
                                    </div>
                                </div>
                            )
                    })}
</div>
</div>

</section>

</>



:null
}
{ p02 === true ?

<>
<section className="goat-search ">
<h2 className="tttt">Seleccione el distrito </h2>
<div className={`sscroll`}>
<div className="hit-hit">
{selectpro.map( ree => {
                            return(
                                
                                <div key={ree.id} className={`provincias  ${ ree.distrito === termdistrito ? 'mari-mari':''}`} 
                                >
                                    <div className="elpapadelos" onClick={ () => {
                                    setTermDistrito(ree.distrito)
                                    setSelectDis(ree.corregimientos)
                                     Corregimiento()
                                    addTermFinal(ree.distrito)
                                    oloader()
                            
                                }}
                                >
                                    <img src={termI} className="icon-normal-size-30" alt="img"/>
                                            <h2 className="">{ree.distrito}</h2>
                                    </div>
                                </div>
                            )
                    })}
</div>
</div>

</section>

<div className={` sscroll sexy-border-right d-none`}>
<h2 className="tttt">Seleccione por distrito </h2>
<div className="sscroll-2 ">
{selectpro.map( ree => {
                            return(

                                <div key={ree.id} className={`combo-lista mangek ${ree.distrito === termdistrito ? 'papitolindo willonfx ':''}`}
                                    onClick={
                                        () => { 
                                            setTermDistrito(ree.distrito)
                                            setSelectDis(ree.corregimientos)
                                            // Corregimiento()
                                            addTermFinal(ree.distrito)
                                            oloader()
                                        }
                                    }       
                                >
                                <img src={termI}/>
                                <div className="single-box">
                                    <h3 className={`single-line`}>Distrito de <span> {ree.distrito} </span></h3>
                                    <p> Provincia de {term}</p>
                                </div>
                            </div>  
                            ) })}
</div>
</div> 


</>


:null
}

{
p02 === true ? 
<div className={`sscroll cool-searchmm d-none`}>
{/* <h2 className="tttt">Busquedas en {term}</h2> */}
<section className={``}>
<div className="provincias-2 " >

                                    <div className="elpapadelos wenwen mari-mari">
                                    <img src={termI} className="icon-normal-size-302" alt="img"/>
                                    </div>     
</div>

</section>
<h2 className="text-center"> {termdistrito}</h2>
{ termdistrito !== "" ?
<p className={`text-center`}>
Distrito con {selectdis.length}  corregimientos 
</p>:null
}

<p className='text-center'>             
                        
                        </p>
</div>

: null
}

{ p03 === true ?



            <>  

                    <section className="goat-search">
                    <h2 className="tttt">Seleccione el corregimiento </h2>
                        <div className={`sscroll`}>
                        <div className="hit-hit">
                        {selectdis.map( ree => {
                                                        return(
                                                            
                                                            <div key={ree.id} className={`provincias  ${ ree.corregimiento === termcorregimiento ? 'mari-mari':''}`} 
                                                            >
                                                                <div className="elpapadelos" onClick={ () => {
                                                                setTermCorregimiento(ree.corregimiento)
                                                                addTermFinal(ree.corregimiento)
                                                                oloader()
                                                                Resumen()
                                                        
                                                            }}
                                                            >
                                                                <img src={termI} className="icon-normal-size-30" alt="img"/>
                                                                        <h2 className="">{ree.corregimiento}</h2>
                                                                </div>
                                                            </div>
                                                        )
                                                })}
                        </div>
                        </div>

                    </section>
            


                <section>
            
            <div className={` sscroll sexy-border-right d-none`}>
            <h2 className="tttt">Seleccione el corregimiento  </h2>
            <div className="sscroll-2 ">
            {selectdis.map( ree => {
                            return(

                                <div key={ree.id} className={`combo-lista mangek ${ree.corregimiento === termcorregimiento ? 'papitolindo willonfx ':''}`}
                                    onClick={
                                        () => { 
                                            setTermCorregimiento(ree.corregimiento)
                                            addTermFinal(ree.corregimiento)
                                            oloader()
                                            
                                        }
                                    }       
                                
                                >
                                <img src={termI}/>
                                <div className="single-box">
                                    <h3 className={`single-line`}>Corregimiento de <span> {ree.corregimiento} </span></h3>
                                    <p>Provincia de {term} | Distrito de {termdistrito} </p>
                                </div>
                            </div>
                                
                            )
                    })}
                </div>
                </div> 
                
                    
                </section>
                </>
                
                :null }
</div>
</section>

<section className="box-bar-papu">   

{
    p04 === false ? 
    
   null : 
    <p className="papidfinal">
    
   
    Complete todos los campos: Presupuesto, número de cuartos, número de baños,
    correo electrónico, número de teléfono, si es para alquiler o compra, si tienes mascotas,
    si lo necesitas amoblado, linea blanca, o vacio, luego de click en el gancho para finalizar
   

    </p>
    
}



        <section className="box-info-master"
        >
 

            <section className="box-busqueda-relative">
            <div className="busqueda-hijo-derecha-box d-none">
                                            
                                            {
                                                  filtro.provincia !== "" ? 
                                                  <>
                                                   <button
                                                        className={`round-btn-ground`}
                                                        
                                                        onClick={LimpiarTodos}
                                                        
                                                >
                                                    {`Atrás`}
                                                </button>   
                                                  </> 
                                                  : 
                                                  <>
                                                     <button  
                                                    className={`round-btn-ground
                                                        negao
                                                    `}
                                                
                                                            >
                                                        {`Seleccione`}
                                                    </button>
                                                  </>
                                            }
                                               


                                                {
                                                    filtro.provincia !== "" ? 
                                                    <>  

                                                    {  
                                                    filtro.distrito !== "" ?
                                                            <>



                                                            {
                                                                filtro.corregimiento !== "" ?

                                                                <button  
                                                                className={`search-btn-ground`}
                                                                onClick={Distrito}
                                                                >
                                                                {`Atrás`}
                                                                </button> 
                                                                
                                                                : 
                                                
                                                                
                                                                <button  
                                                                className={`search-btn-ground`}
                                                                onClick={Corregimiento}
                                                                >
                                                                {`Siguiente`}
                                                                </button> 

                                                            }
                                                        </>
                                                    
                                                    : 
                                                        
                                                    <button  
                                                    className={`search-btn-ground`}
                                                    onClick={Distrito}
                                                    >
                                                    {`Siguiente`}
                                                </button>
                                                

                                                    }

                                                
                                                    </>
                                                    : 
                                                    <button  
                                                    className={`round-btn-ground
                                                        negao
                                                    `}
                                                
                                                            >
                                                        {`Seleccione`}
                                                    </button>
                                                }


                                                <button
                                                        className={`round-btn-ground`}
                                                      
                                                        onClick={sendPropertyData}
                                                    >   
                                                        {`Enviar`}
                                                        
                                                </button>

                                                    

                                            
                                            </div>

                                        
       




            </section>

            <section className="grid-auto-auto">

                <div className='gridx5050'>

                    {
                        p01 === true ? <>
                        
                        <button
                    onClick={ filtro.provincia !== "" ? Provincia : null}
                    className="btn-moviendo-btn">
                        <img
                            src={ant}
                            alt="btn"
                        />

                    </button>

                        </> : <></>
                    }

                    {
                        p02 === true ? <>
                        
                        <button
                  onClick={ filtro.distrito !== "" ? 
                  LimpiarTodos
                  
                  : LimpiarTodos}
                    className="btn-moviendo-btn">
                        <img
                            src={ant}
                            alt="btn"
                        />

                    </button>

                        </> : <></>
                    }

                    {
                        p03 === true ? <>
                        
                        <button
                  onClick={ filtro.provincia !== "" ? Distrito : null}
                    className="btn-moviendo-btn">
                        <img
                            src={ant}
                            alt="btn"
                        />

                    </button>

                        </> : <></>
                    }

                    {
                        p04 === true ? <>
                        
                        <button
                  onClick={ filtro.provincia !== "" ? Corregimiento : null}
                    className="btn-moviendo-btn">
                        <img
                            src={ant}
                            alt="btn"
                        />

                    </button>

                        </> : <></>
                    }


                 

                    {
                        p01 === true ? <>
                        
                        <button 
                    onClick={ filtro.provincia !== "" ? Distrito : null}
                    className="btn-moviendo-btn">
                        <img
                            src={des}
                            alt="btn"
                            
                        />

                    </button>

                        </> : <></>
                    }
                    

                    {
                        p02 === true ? <>
                        
                        <button 
                    onClick={ filtro.distrito !== "" ? Corregimiento : null}
                    className="btn-moviendo-btn">
                        <img
                            src={des}
                            alt="btn"
                            
                        />

                    </button>

                        </> : <></>
                    }
                    
                    
                    {
                        p03 === true ? <>
                        
                        <button 
                    onClick={ filtro.corregimiento !== "" ? Resumen : null}
                    className="btn-moviendo-btn">
                        <img
                            src={des}
                            alt="btn"
                            
                        />

                    </button>

                        </> : <></>
                    }
                    

                </div>

                <div className=''>
                    <button
                    
                    onClick={sendPropertyData}
                    className="btn-moviendo-btn">
                        <img
                            src={sen}
                            alt="btn"
                           
                        />

                    </button>
                </div>


            </section>
    



        </section>

        {
    p04 === false ? 
    <>
    <p className="papid">
Seleccione su Provincia, Distrito, Corregimiento
<br/>

<span>
     Deslize y seleccione la ubicación donde desea la propiedad
</span>

</p>
    </> : 
    null
}

</section>
                    </section>
                    </section>

                </div>

            </>


     );
}
 
export default WindowFormSearch;