import React from 'react'
import  one from '../../assets/why/1png.png'

import girl from '../../imagenes/header/girl.png'
import h2 from '../../imagenes/header/2.jpg'
import h3 from '../../imagenes/header/3.jpg'
import h4 from '../../imagenes/header/4.jpg'
import h5 from '../../imagenes/header/5.jpg'


const About = () => {
    return ( 







        <>
         <section className='section-white'>
          <div className="header-content-goat header-5050">

          <section className="">

<div className="images box-imagen-header-2">
  <img className="header-04" src={girl} alt="Imagen de fondo" />

</div>

</section>


            <div className="text">
              <h1 className="Primary-Size-mm">Búsqueda personalizada, gestionada por tu agente dedicado.</h1>
              <div className='group-about-box-mm'>

                    <div className="group-about-mm">
                      <h2>1</h2>
                      <p>Llena tu formulario con lo que buscas en 5 minutos</p>
                    </div>
                    <div className="group-about-mm">
                      <h2>2</h2>
                      <p>Un agente te contactará en menos de 24 horas.</p>
                    </div>
                    <div className="group-about-mm">
                      <h2>3</h2>
                      <p>Obtenga las mejores opciones de tu búsqueda</p>
                    </div>
                    <div className="group-about-mm">
                      <h2>4</h2>
                      <p>Disfruta de tu hogar de manera rapida y a la medida</p>
                    </div>

              </div>
          
            </div>


          
          </div>
        </section>
        </>

     );
}
 
export default About;