import React, { useState, createContext, useEffect} from 'react'

import { Link, useNavigate } from 'react-router-dom'
import {  PRO , COM } from '../../microdata/dataLocation'




const ActJoemaSearchContext = createContext();

const ActJoemaSearchProvider = ({children}) => {
    /// vamos a colocar los estados del loader 

  //  console.log(PRO)
  //  console.log(COM)
    const [mensaje, setMensaje] = useState('');
    const [ventana, setVentana] = useState(false);
    const ToggleVentana = () => {
        setVentana(!ventana)
        if (mensaje !== ''&& redirect === false )   {
          setMensaje('')
        }
    }
    const [loeader, setLo] = useState(false)
    const oloader = () => {
        setLo(true)
    }
    const cloader = () => {
        setLo(false)
    }
    useEffect( () =>{
        // setTimeout(() => {
        //     cloader()
        //   }, 2000)

          const timer = setTimeout(() => {
            cloader();
          }, 80);
          /// aqui con esto aumentara el tiempo de espera del loader, haciendo la aplicacion mas rapida
          // Limpiar el temporizador cuando el componente se desmonta o cuando loader cambie
          return () => clearTimeout(timer);

          
    },[loeader])

    /// aqui vamos a colocar toda la logica del buscador
    const [ p01 , setP01] = useState(true); //// estado de paso 1 provincias seleccion
    const [ p02 , setP02] = useState(false); /// estado de paso 2 distrito seleccion
    const [ p03 , setP03] = useState(false); /// estado de paso 3 corregimiento
    const [ p04 , setP04] = useState(false); /// estado de paso 4 Resumen
    const [paso, setPaso] = useState(1)
    const Provincia = () => {
        setPaso(1)
        setP01(true);
        setP02(false);
        setP03(false);
        setP04(false);
    }
    const Distrito = () => { /// funcion para activar seleccion de distrito
        setPaso(2)
        setP01(false);
        setP02(true);
        setP03(false);
        setTermCorregimiento("")
        oloader()
        setP04(false);
    }
    const Corregimiento = () =>{ //// funcinon pra activar en el buscador corregimiento
        setPaso(3)
        setP01(false);
        setP02(false);
        setP03(true);
        setP04(false);
    }
    const Resumen = () => {
      setPaso(4)
        setP01(false);
        setP02(false);
        setP03(false);
        setP04(true);
    }
    const [selectpro,setSelectPro] = useState([]) ///// aqui estoy almacenando el nuevo arreglo
    const [selectdis, setSelectDis] = useState([]) 
    const [finalterm, setFinalTerm ] = useState('');
    const [term, setTerm ] = useState(""); //// aqui estoy almacenando la pronvinia en el termino
    // console.log(term)
    const [termI, setTermI] = useState(""); /// aqui estoy almacenando el mapa de la provincia
    const [termdistrito, setTermDistrito ] = useState("") ; //////
    const [termcorregimiento, setTermCorregimiento ] = useState("")



    const [cantidadderesultados, setCantBusqueda ] = useState(0);//// este estado el numero de elementos encontrados
    // const onChange = ( e ) =>{
    //     setTerm(e.target.value)
    //  }
    const addTermFinal = (x) => {
        setFinalTerm(`${x}`)
    }

    // function searchingTerm(finalterm){
    //     return function(x){
    //       return (  
    //                 (x.provincia.includes(finalterm)) || 
    //                 (x.corregimiento.includes(finalterm)) || 
    //                 (x.distrito.includes(finalterm)) ||   
    //                  (!finalterm)  
    //               )
    //     }
    //   }
      ////// aqui vamos a colocar la segunda data de prueba
      const filtro = {
        provincia: term,
        distrito: termdistrito,
        corregimiento: termcorregimiento,
      }; 
    //   console.log(filtro)
     
      /// aqui lo impresionante es que esta reaccionando a la variables , solo con cadena
      /// cone el onClick
      function searchingTerm(terms) {
        return function (x) {
          const provinciaMatch = terms.provincia ? x.provincia.includes(terms.provincia) : true;
          const distritoMatch = terms.distrito ? x.distrito.includes(terms.distrito) : true;
          const corregimientoMatch = terms.corregimiento ? x.corregimiento.includes(terms.corregimiento) : true;
      
          return provinciaMatch && distritoMatch && corregimientoMatch;
        };
      }

      // console.log(filtro)
      // console.log(cantidadderesultados)


    const [monto, setMonto] = useState(0);
    const [numCuartos, setNumCuartos] = useState(0);
    const [numBa, setNumBa] = useState(0);
    const [telefono, setTel] = useState('');
    const [email, setMail] = useState('');
    /////// select dinamico
    const [tipo, setTipo] = useState('Alquiler');
    const [mascota, setMascota] = useState('');
    const [muebles, setMuebles] = useState('');

      //// aqui vamos a hacer redirect

      const [redirect , setRedirect] = useState(false)

    const ResetForm = () => {
      setMonto(0)
      setNumCuartos(0)
      setMonto(0)
      setNumBa(0)
      setTel('')
      setMail('')
      setMascota('')
      setMuebles('')
      setTipo('Alquiler')
      setTermCorregimiento('')
      setTermDistrito('')
      // setTerm('')
      Provincia()
    }
    const Alquilar = () => {
      setTipo('Alquiler')
    }
    const Comprar = () => {
      setTipo('Compra')
    }
    const CompraAlquila = () => {
      if( tipo === 'Alquiler' ) {
          Comprar()
      } else {
        Alquilar()
      }
    }
    const Resettipo = () => {
      setTipo('')
    }
    

    const handleMontoChange = (event) => {
      setMonto(event.target.value);
    };
    const handleNumCuartosChange = (event) => {
      setNumCuartos(event.target.value);
    };
    const handleNumBaChange = (event) => {
      setNumBa(event.target.value);
    };
    const handleT = (event) => {
      setTel(event.target.value);
    };
    const handleM = (event) => {
      setMail(event.target.value);
    };
    const solicitudBusqueda = {
      provincia: term,
      distrito: termdistrito,
      corregimiento: termcorregimiento,
      monto:monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      cuartos:numCuartos,
      telefono:telefono,
      email:email,
      to:'leads@moviendomercado.com',
      bathroom: numBa,
      tipo:tipo,
      mascota:mascota,
      muebles:muebles,
    }; 
    const solicitudBusquedaIn = {
      provincia: term,
      distrito: termdistrito,
      corregimiento: termcorregimiento,
      monto:monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      cuartos:numCuartos,
      telefono:telefono,
      email:email,
      to:email,
      bathroom: numBa,
      tipo:tipo,
      mascota:mascota,
      muebles:muebles,
    }; 
   
    // console.log(solicitudBusqueda)
    
    const sendPropertyDataIN = async () => {
      if (
       solicitudBusqueda.email !== '' && 
       solicitudBusqueda.telefono !== '' && 
       solicitudBusqueda.mascota !== '' && 
       solicitudBusqueda.muebles !== '' && 
       solicitudBusqueda.cuartos !== 0 && 
       solicitudBusqueda.bathroom !== 0 &&
       solicitudBusqueda.monto !== 0      ) {

      
     try {
       const response = await fetch('https://find.tierraspanama.com/webservices/send.php', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           // 'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
         },
         body: JSON.stringify(solicitudBusquedaIn),
       });
       
       const data = await response.json();
       setMensaje(data.message)
       ToggleVentana()
       ResetForm()
       setRedirect(true)
       if (!response.ok) {
         throw new Error('Failed to send data to server');
       }
       return 
     } catch (error) {
       console.error('Error:', error.message);
       throw error;
     }

     } else {
       setMensaje(`Complete todos los campos: 
       Presupuesto, número de cuartos,
       número de baños, correo electrónico,
       número de teléfono, si es para 
       alquiler o compra, si tienes mascotas, 
       si lo necesitas amoblado, linea blanca, 
       o vacio, luego de click en el gancho para finalizar`)
     }
     setRedirect(false)

   };



    const sendPropertyData = async () => {
       if (
        solicitudBusqueda.email !== '' && 
        solicitudBusqueda.telefono !== '' && 
        solicitudBusqueda.mascota !== '' && 
        solicitudBusqueda.muebles !== '' && 
        solicitudBusqueda.cuartos !== 0 && 
        solicitudBusqueda.bathroom !== 0 &&
        solicitudBusqueda.monto !== 0      ) {

       
      try {
        const response = await fetch('https://find.tierraspanama.com/webservices/send.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
          },
          body: JSON.stringify(solicitudBusqueda),
        });
        
        const data = await response.json();
        setMensaje(data.message)
        ToggleVentana()
        ResetForm()
        setRedirect(true)
        sendPropertyDataIN()
        if (!response.ok) {
          throw new Error('Failed to send data to server');
        }
        return 
      } catch (error) {
        console.error('Error:', error.message);
        throw error;
      }

      } else {
        setMensaje(`Complete todos los campos: 
        Presupuesto, número de cuartos,
        número de baños, correo electrónico,
        número de teléfono, si es para 
        alquiler o compra, si tienes mascotas, 
        si lo necesitas amoblado, linea blanca, 
        o vacio, luego de click en el gancho para finalizar`)
      }
      setRedirect(false)

    };

  

   


    return (
        <ActJoemaSearchContext.Provider
            value={{  
                searchingTerm,
                term,
                setTerm,
                termI,
                setTermI,
                setSelectPro,
                selectpro,
                Provincia,
                Distrito,
                Corregimiento,
                Resumen,
                p01,
                p02,
                p03,
                p04,
                paso,
                selectdis, setSelectDis,
                termdistrito, setTermDistrito,
                addTermFinal,
                finalterm,
                setFinalTerm,
                termcorregimiento, setTermCorregimiento,
                filtro,
                loeader,
                oloader,cantidadderesultados, setCantBusqueda,
                monto, 
                numCuartos,
                numBa,
                handleMontoChange,
                handleNumCuartosChange,
                handleNumBaChange,
                telefono,
                email,
                handleM,
                handleT,
                sendPropertyData,
                tipo,
                CompraAlquila,
                Alquilar,
                Comprar,
                Resettipo,
                mascota,setMascota,
                muebles, setMuebles,
                mensaje,ventana,ToggleVentana,
                redirect
                


            }}
        >


            {children}
        </ActJoemaSearchContext.Provider>

    )


}

export {
    ActJoemaSearchProvider
}

export default ActJoemaSearchContext;