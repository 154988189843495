import React from 'react'
import useSearchJoe from '../../hooks/useSearchJoe';



const M = () => {

    const {
        mensaje,
        ventana,ToggleVentana,redirect,
        term,
    } = useSearchJoe()


    return (  
            <>
            {
               
               mensaje !== '' ? 
               <section className='box-mensaje'>
                <div>
                    
                <h2>{mensaje}</h2>

             {  redirect !== false ?
             
             <a
             className=""
             href={`https://moviendomercado.com/properties?type=sale&k=${term}`}
             >
             Aceptar
            </a> : 
            <button
            className=""
            onClick={ToggleVentana}
        >
            Aceptar
        </button>


             }

                </div>
                
            </section>
               : 
               <> 
               </>



            }
            </>


    );
}
 
export default M;