import React from 'react';
import './App.css';
import './components/styles/bienes.css';
import Index from '../src/components/Pages/Index.jsx'

import { ActJoemaSearchProvider } from './context/Actions/ActJoemaSearchContext.jsx';
import { ActJoemaProvider } from  './context/Actions/ActJoemaContext.jsx';

import { Switch, Navigate, BrowserRouter as  Router, Routes, Route } from "react-router-dom";

import { BrowserRouter } from "react-router-dom";

function App() {
  return (

    <BrowserRouter>
    <ActJoemaProvider>
    <ActJoemaSearchProvider>


          <Routes  >

                        <Route index element={ <Index/>} />
                       
                    </Routes>
    </ActJoemaSearchProvider>
    </ActJoemaProvider>

    </BrowserRouter>
  );
}

export default App;
