import React from 'react'
import  one from '../../assets/why/1png.png'

import h1 from '../../imagenes/header/1.jpg'
import h2 from '../../imagenes/header/2.jpg'
import h3 from '../../imagenes/header/3.jpg'
import h4 from '../../imagenes/header/4.jpg'
import h5 from '../../imagenes/header/5.jpg'
import r1 from '../../imagenes/header/r1.jpg'
import r2 from '../../imagenes/header/r2.jpg'
import r3 from '../../imagenes/header/r3.jpg'
import Logo from '../../assets/Logo.png'


const Header = () => {
    return ( 







        <>
         <header>
          <div className="header-content-goat">
            <div className="text">
            <div className="logo">
          <img className="LogoMoviendo"src={Logo} alt="Logo" />
        </div>
              <h1 className="Primary-Size-mm">Simplificamos la búsqueda de hogares en todo el país <br/> 
              <span className='font-bold'>Gratis</span>
              </h1>
              <h3 className="Sec-Size-mm">Te conectamos con los mejores agentes inmobiliarios para acelerar tu proceso de búsqueda de hogar.</h3>
              <button
              className='btn-mm-new d-none'
              >Comenzar</button>
            </div>


            <section className="max-header-width">

            <div className="image box-imagen-header">
              <img className="header-01" src={h1} alt="Imagen de fondo" />
              <img className="header-02" src={h2} alt="Imagen de fondo" />
            </div>

            <div className="image box-imagen-header-tres">
              <img className="header-02" src={r1} alt="Imagen de fondo" />
              <img className="header-02" src={r2} alt="Imagen de fondo" />
              <img className="header-02" src={r3} alt="Imagen de fondo" />
            </div>
            <div className="image box-imagen-header">
              <img className="header-02" src={h4} alt="Imagen de fondo" />
              <img className="header-01" src={h5} alt="Imagen de fondo" />
            </div>


            </section>
          </div>
        </header>
        </>

     );
}
 
export default Header;